<!--
 功能名称:千里眼平台(短信)-首页
 开发人:梁高权
 创建时间:2021/05/20
 最后修改时间:2021/05/20
-->
<template>
  <div class="index-wrap">

    <div class="panel">
      <van-cell :title="shopNameTitle" is-link value="切换" @click="$router.push('cut')" />
      <div class="panel-content" v-if="indexinfo">
        <h2>{{indexinfo.next_holiday.holiday}}</h2>
        <div class="panel-time">
          <p>{{indexinfo.curr_date.ymd|dateFormat}} {{indexinfo.curr_date.lunar}}</p>
          <div>
            <img src="@/assets/images/time-ion.png" />
            <span>距离剩余{{indexinfo.diff_day}}天</span>
          </div>
        </div>
        <div class="panel-buib">
          <img src="@/assets/images/buib-ion.png" />
          <span>业绩好的门店都提前发送了营销短信，快给客户发送短信吧！</span>
        </div>
        <div class="GetSimNum">
          <div>
            <span>短信剩余条数</span>
            <i>{{indexinfo.sms_num}}条</i>
          </div>
          <div @click="callPhone('073182297060')">我要充值短信</div>
        </div>
      </div>
    </div>

    <div class="easyMessage">
      <div @click="$router.push('newNote')">
        <img src="@/assets/images/massTexting.png" />
        <span>群发短信</span>
      </div>
      <div @click="$router.push('sendRecord')">
        <img src="@/assets/images/beenTexting.png" />
        <span>已发短信</span>
      </div>
    </div>

    <div class="group">
      <div class="title" @click="show = !show">
        <span>会员群组(&nbsp;{{total_rows}}&nbsp;)</span>
        <van-icon name="arrow-down" size="16" />
      </div>
      <div v-show="show" class="line" v-for="(item, index) in groupList" :key= "index">
        <div>
          <span>{{ item.group_name }}</span>
          <i>(&nbsp;{{ item.member_num }}人&nbsp;)</i>
        </div>
        <div>
          <span @click="memberGroupDel(item.id)">删除</span>
          <span @click="$router.push({name:'groupSMS',query:item})">再次发送</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import table from '@/api/table';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'index',
  components: {},
  filters: {
    // 格式化日期
    dateFormat(val){
      return (val||'').substring(0,4)+'年'+(val||'').substring(5,7)+'月'+(val||'').substring(8,10)+'日'
    }
  },
  mixins: [],
  props: {},
  data() {
    return {
      shopNameTitle:'',
      show: true,
      indexinfo:null,
      total_rows:0,
      groupList: []
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.indexedDB();
    this.memberGroup();
    this.shopNameTitle=localStorage.getItem('shopNameTitle')
  },
  methods: {
    ...mapMutations(['updateSmsNum']),
    // 调用手机电话功能
    callPhone (phoneNumber) {location.href = 'tel:' + phoneNumber},
    // 首页详情
    indexedDB() {
      table.indexedDB().then(res => {
        this.indexinfo=res.data
        this.updateSmsNum(res.data.sms_num)
      });
    },
    // 群组列表
    memberGroup(){
      table.memberGroup({page:1,limit:2000}).then(res=>{
        this.total_rows=res.data.total_rows
        this.groupList=res.data.list
      })
    },
    // 群组-删除
    memberGroupDel(id){
      let _this=this
      table.memberGroupDel({id}).then(function(res){
        if(res.data.result){
          _this.memberGroup()
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.index-wrap {
  .panel {
    position: relative;
    height: 475px;
    color: #fff;
    background: url('../../assets/images/index_background.png') no-repeat center/100% 100%;
    .panel-content {
      padding: 63px 30px 0;
      h2 {
        font-size: 70px;
        font-weight: bold;
        font-family: PingFang SC;
        line-height: 70px;
      }
      .panel-time {
        font-size: 26px;
        display: flex;
        justify-content: space-between;
        margin-top: 33px;
        > div {
          display: flex;
          align-items: center;
          img {
            margin-right: 11px;
            width: 25px;
            height: 25px;
          }
        }
      }
      .panel-buib {
        display: flex;
        align-items: center;
        margin-top: 74px;
        img {
          margin-right: 11px;
          width: 18px;
        }
        span{font-size: 23px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis}
      }
      .GetSimNum {
        position: absolute;
        bottom: 0;
        background: #fff;
        color: #4e4e4e;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 690px;
        padding: 0 20px;
        height: 102px;
        transform: translateY(50%);
        border-radius: 16px;
        div:nth-child(1) {
          font-size: 28px;
          span {
            margin-right: 5px;
          }
          i {
            color: #fe4600;
          }
        }
        div:nth-child(2) {
          font-size: 24px;
          color: #d05f00;
          background: #ffefc5;
          border-radius: 21px;
          padding: 9px 25px;
        }
      }
    }
    .van-cell {
      background-color: rgba(0, 0, 0, 0);
      line-height: 95px;
      padding: 0 30px;
      .van-cell__title {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
      }
      .van-cell__value {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
      }
      .van-icon {
        line-height: 95px;
        color: #fff;
      }
    }
    .van-cell::after {
      opacity: 0.05;
    }
  }
  .easyMessage {
    margin-top: 83px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      width: 335px;
      height: 95px;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      img {
        width: 34px;
        margin-right: 16px;
      }
      span{
        font-size: 28px;
        font-weight: bold;
        color: #444745;
      }
    }
  }
  .group {
    padding: 0 30px;
    .title {
      display: flex;
      padding: 40px 0 30px;
      justify-content: space-between;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #6e7370;
    }
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      height: 103px;
      background: #ffffff;
      border-radius: 16px;
      font-size: 28px;
      font-family: PingFang SC;
      margin-bottom: 18px;
      > div:nth-child(1) {
        font-weight: bold;
        color: #444745;
        i {
          font-size: 24px;
          font-weight: 500;
          color: #6e7370;
          margin-left: 15px;
        }
      }
      > div:nth-child(2) {
        font-weight: 500;
        color: #999999;
        span:nth-child(2) {
          color: #4d81ef;
          margin-left: 38px;
        }
      }
    }
  }
}
</style>
